
export default {
  data () {
    return {
      order: null,
      orderId: 3698
    }
  },
  beforeDestroy () {
    if (this.$echo && typeof this.$echo.leaveChannel === 'function') {
      this.$echo.leaveChannel('locopack-payment-success')
      this.$echo.disconnect()
    }
  },
  async mounted () {
    // Import the plugin dynamically
    const createEcho = (await import('~/plugins/pusher-echo.js')).default

    // Create an Echo instance
    this.$echo = createEcho()
    this.$echo.channel('locopack-payment-success')
      .on('payment-success', (data) => {
        console.log('### DEBUG PUSHER >> Received event data:', data)
        // ทำสิ่งที่คุณต้องการกับข้อมูลที่ได้รับที่นี่
      })
  },
  methods: {
    openWeChatPay () {
      const url = 'weixin://wxpay/bizpayurl?pr=202402083yCwpv8gMa2IyLp'
      window.open(url)
    },
    openSCB () {
      const url = 'scbeasysim://purchase/b462489a-dfa4-48be-b12c-ced775caff50'
      window.open(url)
    }
  },
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      const { data } = await $axios.$get(`orders/${params.id}`)
      return {
        order: data
      }
    } catch (e) {
      console.log('error', e)
      redirect('/')
    }
  }
}
